<template>
    <div>
        <TitleBar>授权平台管理</TitleBar>
        <div class="main-container">
            <h1>已授权平台</h1>
            <ul class="official-account-list">
                <li v-for="channel in ChannelList" :key="channel.channelKey + channel.id">
                    <OfficialAccount
                        v-if="channel.channelKey === 'official_account'"
                        v-on:unbind="unbindAccount"
                        :id="channel.id"
                        :avatar="channel.avatar"
                        :name="channel.nickname"
                        :principal-name="channel.principalName"
                        :auth-time="channel.authTime"
                    ></OfficialAccount>
                    <MiniprogramAccount
                        v-else-if="channel.channelKey === 'miniprogram_account'"
                        v-on:unbind="unbindMiniProgramAccount"
                        :id="channel.id"
                        :avatar="channel.avatar"
                        :name="channel.nickname"
                        :principal-name="channel.principalName"
                        :auth-time="channel.authTime"
                    ></MiniprogramAccount>
                    <Yunpian
                        v-else-if="channel.channelKey === 'yunpian'"
                        v-on:unbind="unbindSmsAccount"
                        :id="channel.id"
                        :phone="channel.phone"
                        :balance="channel.balance"
                        :auth-time="channel.authTime"
                    ></Yunpian>
                    <Wecom
                        v-else-if="channel.channelKey === 'wecom'"
                        v-on:unbind="unbindWecomAccount"
                        :id="channel.id"
                        :name="channel.name"
                        :corp-id="channel.corpId"
                        :agent-id="channel.agentId"
                        :auth-time="channel.authTime"
                    ></Wecom>
                </li>
                <li class="new-auth" v-on:click="newChannel">
                    <div class="add"><i class="nazaio-iconfont nz-add" /></div>
                    <h2>新增授权</h2>
                </li>
            </ul>
        </div>
        <Modal v-model="showNewDialog" width="600" title="新建授权" class="new-modal">
            <div class="new-con">
                <div @click="newAuth" class="con-item">
                    <img src="../../assets/wechat.png" />
                    <h3>微信公众号</h3>
                </div>
                <div @click="newYunpian" class="con-item">
                    <img src="../../assets/yunpian.png" />
                    <h3>云片网</h3>
                </div>
                <div @click="newMiniProgram" class="con-item">
                    <img src="../../assets/mini-program.png" />
                    <h3>微信小程序</h3>
                </div>
            </div>
            <div class="new-con">
                <div @click="newWecom" class="con-item">
                    <img src="../../assets/wechat-work.png" />
                    <h3>企业微信</h3>
                </div>
                <div class="con-item"></div>
                <div class="con-item"></div>
            </div>
            <div slot="footer">
                <Button @click="showNewDialog = false">关闭</Button>
            </div>
        </Modal>
        <Modal v-model="showNewYunpian" width="400" title="连接云片网" class="new-modal">
            <div class="new-yuncon">
                <h2>API Key</h2>
                <div class="input-con">
                    <Input v-model="apiKey" placeholder="请输入API Key" />
                </div>
            </div>
            <div slot="footer">
                <Button @click="showNewYunpian = false">取消</Button>
                <Button type="primary" :loading="loading" @click="bindYunpian">确定</Button>
            </div>
        </Modal>
        <Modal v-model="showNewWecom" width="600" title="连接企业微信" class="new-modal">
            <div class="new-wecom">
                <h2>企业ID</h2>
                <div class="input-con">
                    <Input v-model="newWecomForm.corpId" placeholder="请输入" />
                </div>
            </div>
            <div class="new-wecom">
                <h2>Agent ID</h2>
                <div class="input-con">
                    <Input v-model="newWecomForm.agentId" placeholder="请输入" />
                </div>
            </div>
            <div class="new-wecom">
                <h2>Corp Secret</h2>
                <div class="input-con">
                    <Input v-model="newWecomForm.secret" placeholder="请输入" />
                </div>
            </div>
            <div slot="footer">
                <Button @click="showNewWecom = false">取消</Button>
                <Button type="primary" :loading="loading" @click="bindWecom">确定</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import TitleBar from "@/components/TitleBar";
import Yunpian from "./components/Yunpian";
import OfficialAccount from "./components/OfficialAccount";
import MiniprogramAccount from "./components/MiniprogramAccount";
import Wecom from "./components/Wecom";

import {
    getList,
    getAuthPageUrl,
    getMiniprogramAuthPageUrl,
    bindYunpian,
    unbindOfficialAccount,
    unbindMiniprogramAccount,
    unbindSmsAccount,
    bindWecom,
    unbindWecomAccount,
} from "@/api/channel";

export default {
    name: "ChannelList",
    components: {
        TitleBar,
        OfficialAccount,
        Yunpian,
        MiniprogramAccount,
        Wecom,
    },
    created() {
        this.getChannelList();
    },
    data() {
        return {
            ChannelList: [],
            showNewDialog: false,
            showNewYunpian: false,
            apiKey: undefined,
            loading: false,
            showNewWecom: false,
            newWecomForm: {},
        };
    },
    methods: {
        getChannelList() {
            getList().then((response) => {
                this.ChannelList = response;
            });
        },
        unbindAccount(id) {
            this.$Modal.confirm({
                title: "确定解绑公众号吗？",
                content: "<p>如果解绑当前公众号，系统中使用到该账号的轻页面将无法正常收集相关信息，确定要解绑该账号吗？</p>",
                onOk: () => {
                    unbindOfficialAccount(id).then(() => {
                        this.$Message.success("解绑成功！");
                        this.getChannelList();
                    });
                },
            });
        },
        unbindSmsAccount(id) {
            this.$Modal.confirm({
                title: "确定解绑云片网吗？",
                content: "<p>如果解绑当前短信平台，短信校验的功能将无法正常使用，导致信息收集异常，确定要解绑该平台吗？</p>",
                onOk: () => {
                    unbindSmsAccount(id).then(() => {
                        this.$Message.success("解绑成功！");
                        this.getChannelList();
                    });
                },
            });
        },
        unbindMiniProgramAccount(id) {
            this.$Modal.confirm({
                title: "确定解绑小程序吗？",
                content: "<p>如果解绑当前小程序，系统中使用到该账号的相关功能将无法使用，确定要解绑该账号吗？</p>",
                onOk: () => {
                    unbindMiniprogramAccount(id).then(() => {
                        this.$Message.success("解绑成功！");
                        this.getChannelList();
                    });
                },
            });
        },
        unbindWecomAccount(id) {
            this.$Modal.confirm({
                title: "确定解绑企业微信吗？",
                content: "<p>如果解绑当前企业微信，系统中使用到该账号的相关功能将无法使用，确定要解绑该账号吗？</p>",
                onOk: () => {
                    unbindWecomAccount(id).then(() => {
                        this.$Message.success("解绑成功！");
                        this.getChannelList();
                    });
                },
            });
        },
        newChannel() {
            this.showNewDialog = true;
        },
        newAuth() {
            getAuthPageUrl(location.href).then((response) => {
                location.href = response;
            });
        },
        newYunpian() {
            this.showNewDialog = false;
            this.apiKey = undefined;
            this.loading = false;
            this.showNewYunpian = true;
        },
        bindYunpian() {
            if (!this.apiKey) {
                this.$Message.error("请输入API Key！");
                return;
            }
            this.loading = true;
            bindYunpian(this.apiKey)
                .then(() => {
                    this.$Message.success("绑定成功！");
                    this.showNewYunpian = false;
                    this.loading = false;
                    this.getChannelList();
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        newMiniProgram() {
            getMiniprogramAuthPageUrl(location.href).then((response) => {
                location.href = response;
            });
        },
        newWecom() {
            this.showNewDialog = false;
            this.newWecomForm = {
                corpId: null,
                agentId: null,
                secret: null,
            };
            this.loading = false;
            this.showNewWecom = true;
        },
        bindWecom() {
            if (!this.newWecomForm.corpId) {
                this.$Message.error("请输入企业ID！");
                return;
            }
            if (!this.newWecomForm.agentId) {
                this.$Message.error("请输入Agent ID！");
                return;
            }
            if (!this.newWecomForm.secret) {
                this.$Message.error("请输入Corp Secret！");
                return;
            }
            bindWecom(this.newWecomForm)
                .then(() => {
                    this.$Message.success("绑定成功！");
                    this.showNewWecom = false;
                    this.loading = false;
                    this.getChannelList();
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
<style lang="less">
.new-modal {
    .ivu-modal-body {
        padding: 0;
    }
}

.new-con {
    width: 600px;
    height: 154px;
    display: flex;
    justify-content: space-evenly;
    padding-top: 32px;

    &:last-child {
        margin-bottom: 32px;
    }
}

.con-item {
    width: 88px;
    cursor: pointer;

    img {
        display: block;
        width: 88px;
        height: 88px;
        border-radius: 44px;
    }

    h3 {
        font-size: 14px;
        color: #0a2a4c;
        font-weight: 400;
        text-align: center;
        margin: 12px 0 0 0;
        height: 22px;
        line-height: 22px;
    }
}

.new-yuncon {
    padding: 24px;

    h2 {
        font-size: 14px;
        color: #0a2a4c;
        height: 20px;
        line-height: 20px;
        margin: 0;
    }

    .input-con {
        margin-top: 12px;
    }
}

.new-wecom {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;

    &:first-child {
        padding-top: 24px;
    }

    h2 {
        font-size: 14px;
        font-weight: normal;
        color: #0a2a4c;
        height: 22px;
        line-height: 22px;
        margin: 0;
    }

    .input-con {
        margin-top: 8px;
    }
}
</style>
<style lang="less" scoped>
h1 {
    font-size: 16px;
    text-align: center;
    color: #0a2a4c;
    height: 20px;
    line-height: 20px;
    margin: 12px 0 20px 0;
}

.official-account-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    width: 960px;
    margin: 0 auto;

    li {
        width: 300px;
        height: 147px;
        background-color: #fff;
        border-radius: 2px;
        border: 1px solid #e8eaec;
        box-sizing: border-box;
        margin: 0 10px 32px 10px;
        position: relative;

        &:hover {
            box-shadow: 0px 5px 20px -4px #d4dbe1;
        }
    }
}

.add {
    text-align: center;
    color: #395069;
    margin-top: 37px;

    i {
        font-size: 40px;
        line-height: 40px;
    }
}

.new-auth {
    cursor: pointer;

    h2 {
        font-size: 14px;
        font-weight: 400;
        color: #395069;
        text-align: center;
        height: 22px;
        line-height: 22px;
        margin: 12px 0 0 0;
    }
}
</style>