<template>
    <div class="channel-item">
        <div class="channel-header">
            <h2>{{ name }}</h2>
            <img src="../../../assets/wechat-work.png" />
        </div>
        <div class="info-item">
            <h3>企业ID</h3>
            <h4>{{ corpId }}</h4>
        </div>
        <div class="info-item">
            <h3>Agent ID</h3>
            <h4>{{ agentId }}</h4>
        </div>
        <div class="info-item">
            <h3>授权时间</h3>
            <h4>{{ authTime }}</h4>
        </div>
        <button v-on:click="$emit('unbind', id)"><i class="nazaio-iconfont nz-disconnect" /></button>
    </div>
</template>
<script>
export default {
    name: "Wecom",
    props: ["id", "name", "corpId", "agentId", "authTime"],
};
</script>
<style lang="less" scoped>
.channel-item {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 20px;
    box-sizing: border-box;

    &:hover {
        button {
            display: block;
        }
    }
}

.channel-header {
    overflow: hidden;
    margin: -5px 0 12px 0;
    height: 32px;

    h2 {
        float: left;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        color: #0a2a4c;
        font-weight: bold;
        margin: 0;
    }

    img {
        float: right;
        border-radius: 16px;
        display: block;
        width: 32px;
        height: 32px;
    }
}

.info-item {
    margin-top: 6px;
    overflow: hidden;

    h3 {
        float: left;
        margin: 0;
        width: 70px;
        font-size: 13px;
        color: #8494a6;
        font-weight: 400;
        height: 20px;
        line-height: 20px;
    }

    h4 {
        float: right;
        margin: 0;
        width: 188px;
        font-size: 13px;
        color: #395069;
        font-weight: 400;
        height: 20px;
        line-height: 20px;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

button {
    display: none;
    position: absolute;
    width: 32px;
    height: 32px;
    border: none;
    outline: none;
    text-align: center;
    padding: 0;
    line-height: 32px;
    background-color: #ed4014;
    color: #fff;
    border-radius: 16px;
    right: -16px;
    top: -16px;
    cursor: pointer;
}
</style>