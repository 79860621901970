import { render, staticRenderFns } from "./MiniprogramAccount.vue?vue&type=template&id=46dabfec&scoped=true&"
import script from "./MiniprogramAccount.vue?vue&type=script&lang=js&"
export * from "./MiniprogramAccount.vue?vue&type=script&lang=js&"
import style0 from "./MiniprogramAccount.vue?vue&type=style&index=0&id=46dabfec&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46dabfec",
  null
  
)

export default component.exports