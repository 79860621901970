import request from '@/util/request';
import qs from "qs";

export function getList() {
    return request({
        url: '/channel',
        method: 'get'
    });
};

export function getSmsAccountList() {
    return request({
        url: '/channel/smsAccountList',
        method: 'get'
    });
}

export function getMiniprogramList() {
    return request({
        url: '/channel/miniprogramList',
        method: 'get'
    });
}

export function getAuthPageUrl(currentUrl) {
    return request({
        url: '/channel/getAuthPageUrl',
        method: 'post',
        data: qs.stringify({ currentUrl })
    });
}

export function getMiniprogramAuthPageUrl(currentUrl) {
    return request({
        url: '/channel/getMiniprogramAuthPageUrl',
        method: 'post',
        data: qs.stringify({ currentUrl })
    });
}

export function bindYunpian(apiKey) {
    return request({
        url: '/channel/bindYunpian',
        method: 'post',
        data: qs.stringify({ apiKey })
    });
}

export function unbindOfficialAccount(id) {
    return request({
        url: '/channel/unbindOfficialAccount/' + id,
        method: 'put'
    });
}

export function unbindMiniprogramAccount(id) {
    return request({
        url: '/channel/unbindMiniprogramAccount/' + id,
        method: 'put'
    });
}

export function unbindSmsAccount(id) {
    return request({
        url: '/channel/unbindSmsAccount/' + id,
        method: 'put'
    });
}

export function getYunpianTemplateList(smsAccountId) {
    return request({
        url: '/channel/getYunpianTemplateList',
        method: 'get',
        params: { smsAccountId }
    });
}

export function bindWecom(data) {
    return request({
        url: '/channel/bindWecom',
        method: 'post',
        data: qs.stringify(data)
    });
}

export function unbindWecomAccount(id) {
    return request({
        url: '/channel/unbindWecomAccount/' + id,
        method: 'put'
    });
}